import React from 'react';
import './HostingSharedAdvice.css'; // برای استایل دهی
import QM1 from '../../../src/Images/QM1.svg';
import QM2 from '../../../src/Images/QM2.svg';
import QM3 from '../../../src/Images/QM3.svg';
import QM4 from '../../../src/Images/QM4.svg';

const HostingSharedAdvice = () => {
    return (
        <div className="hosting-advice-container">
            <div className="main-container">
                <img src={QM1} alt="Hosting" className="main-image" />
                <h2 className="main-title">مشاوره پیش از خرید هاست اشتراکی</h2>
                <p className="main-description">
                    برای خرید هاست مشترک مناسب، ابتدا باید نیازهای وب‌سایت خود را مشخص کنید. اگر وب‌سایت شما تازه‌کار است و ترافیک زیادی ندارد، هاست مشترک به دلیل هزینه پایین گزینه مناسبی است. اطمینان حاصل کنید که شرکت هاستینگ پشتیبانی فنی 24 ساعته و تدابیر امنیتی مانند گواهی SSL ارائه می‌دهد. همچنین، منابعی مانند فضای ذخیره‌سازی و پهنای باند باید کافی و متناسب با نیازهای شما باشند. نظرات کاربران قبلی را بررسی کنید تا از کیفیت خدمات مطمئن شوید و امکان ارتقا به پلن‌های بالاتر در آینده وجود داشته باشد.
                </p>
            </div>

            <div className="additional-info-container">
                <div className="info-item">
                    <img src={QM3} alt="Advice 1" className="info-image" />
                    <h3 className="info-title">تفاوت هاست اشتراکی با سایر سرویس‌ها</h3>
                    <p className="info-description">در هاست اشتراکی، منابع سرور مانند فضای دیسک و پهنای باند بین چندین وب‌سایت به اشتراک گذاشته می‌شود، که این امر باعث کاهش هزینه‌ها می‌شود و برای وب‌سایت‌های کوچک مناسب است. در مقابل، سرور اختصاصی تمام منابع را به یک کاربر اختصاص می‌دهد، که منجر به عملکرد بهتر و امنیت بالاتر می‌شود اما هزینه بیشتری دارد. سرور مجازی (VPS) نیز گزینه‌ای بینابینی است که کنترل بیشتری نسبت به هاست اشتراکی ارائه می‌دهد اما همچنان منابع را به صورت مجازی تقسیم می‌کند.</p>
                </div>
                <div className="info-item">
                    <img src={QM2} alt="Advice 2" className="info-image QM2" />
                    <h3 className="info-title">خدمات پشتیبانی هاست‌های اشتراکی</h3>
                    <p className="info-description">‎خدمات پس از فروش هاست اشتراکی شامل چندین جنبه مهم است که به کاربران کمک می‌کند تا تجربه بهتری از سرویس خود داشته باشند. یکی از این خدمات، پشتیبانی فنی 24 ساعته است که به کاربران امکان می‌دهد در هر زمان مشکلات فنی خود را با کارشناسان در میان بگذارند و راه‌حل‌های سریع دریافت کنند.
                        ‎همچنین، بسیاری از شرکت‌های هاستینگ گارانتی بازگشت وجه ارائه می‌دهند که به کاربران اجازه می‌دهد در صورت نارضایتی از سرویس، طی یک دوره مشخص هزینه خود را پس بگیرند. پشتیبان‌گیری منظم و مانیتورینگ دائمی نیز از دیگر خدمات مهم است که امنیت و پایداری وب‌سایت‌ها را تضمین می‌کند.</p>
                </div>
            </div>

            <div className="main-container QM4">
                <img src={QM4} alt="Hosting" className="main-image" />
                <h2 className="main-title">قیمت هاست اشتراکی</h2>
                <p className="main-description">
                    هاست‌های داخلی با توجه به استقرار سرورها در ایران، سرعت بارگذاری بالاتری را برای بازدیدکنندگان ایرانی فراهم می‌کنند. این سرعت بالا به دلیل کاهش زمان تاخیر و فاصله کمتر با کاربران است. این نوع هاست‌ها انتخاب مناسبی برای وبلاگ‌ها، سایت‌های شخصی و کسب‌وکارهای کوچک داخلی هستند. علاوه بر این، پشتیبانی از درگاه‌های پرداخت داخلی و دسترسی پایدار در شرایط مختلف از دیگر مزایای آنهاست.
                </p>
            </div>
        </div>
    );
};

export default HostingSharedAdvice;
