import React from 'react';
import './WpHostingFeatures.css'; // برای استایل‌دهی

// img
import WP4 from '../../../src/Images/WP4.svg';

const WpHostingFeatures = () => {
  // ویژگی‌های هاست وردپرس
  const features = [
    { title: 'فضای کافی', description: 'فضای مناسب از ویژگی‌های اصلی هاست وردپرسی است. این فضا باید به اندازه‌ای باشد که پس از خرید برای شما مشکلی ایجاد نکند .' },
    { title: 'ارائه خدمات', description: 'اگر از هاست وردپرس استفاده کنید می‌توانید از خدمات مختلفی مانند ایمیل بهره‌مند شوید. این ویژگی به شما امکان می‌دهد ایمیل مورد نظر خود را به آدرس وب‌سایت متصل کنید .' },
    { title: 'عدم محدودیت', description: 'از دیگر ویژگی‌های هاست وردپرس می‌توان به عدم محدودیت آن در Max Upload Size و Max Post Size اشاره کرد. محدود بودن ممکن است شما را در آپلود پست‌ها با مشکل مواجه کند‌ .' },
    { title: 'کنترل پنل با رابط کاربری ساده', description: 'هاست وردپرس با کنترل پنل DirectAdmin و CPanel ‌ مدیریت وب‌سایت‌ها را با یک رابط کاربری ساده برای کاربران فراهم کرده است .' },
    { title: 'آشنایی با امکانات هاست‌ وردپرسی', description: 'علاوه‌بر موارد فوق هاست وردپرسی امکانات و ویژگی‌های دیگری نیز دارد. امکانات بیشتر هاست وردپرسی عبارتند از :' },
    { title: 'قابلیت Virus & Malware Scanning'},
    { title: 'قابلیت سرویس دهی'},
    { title: 'ارائه خدمات متنوع'},
    { title: 'Security مناسب'},
    { title: 'قابلیت Caching'},
    { title: 'قابلیت Uptime'},
    { title: 'قیمت مناسب'},
    { title: 'سرعت بالا'},
  ];

  return (
    <div className="hosting-features-container">
      <div className="main-container">
        <img src={WP4} alt="Hosting" className="main-image" />
        <h2 className="main-title">ویژگی‌های هاست وردپرس</h2>
        <p className="main-description">
        هاست وردپرسی ویژگی‌های مختلفی دارد که آن را از دیگر ویژگی‌ها متمایز کرده است‌ . مهم‌ترین ویژگی‌های هاست وردپرس عبارتند از :
        </p>
      </div>

      <div className="scrolling-container">
        {/* ردیف اول */}
        <div className="card-row-wrapper">
          <div className="card-row">
            {features.slice(0, 5).map((feature, index) => (
              <div className="card" key={index}>
                <h3 className="card-title">{feature.title}</h3>
                <p className="card-description">{feature.description}</p>
              </div>
            ))}
          </div>
          <div className="card-row">
            {features.slice(0, 5).map((feature, index) => (
              <div className="card" key={index}>
                <h3 className="card-title">{feature.title}</h3>
                <p className="card-description">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* ردیف دوم (اسکرول مخالف) */}
        <div className="card-row-wrapper reverse-scroll">
          <div className="card-row">
            {features.slice(5, 10).map((feature, index) => (
              <div className="card" key={index}>
                <h3 className="card-title">{feature.title}</h3>
                <p className="card-description">{feature.description}</p>
              </div>
            ))}
          </div>
          <div className="card-row">
            {features.slice(5, 10).map((feature, index) => (
              <div className="card" key={index}>
                <h3 className="card-title">{feature.title}</h3>
                <p className="card-description">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* ردیف سوم */}
        <div className="card-row-wrapper">
          <div className="card-row">
            {features.slice(10, 14).map((feature, index) => (
              <div className="card" key={index}>
                <h3 className="card-title">{feature.title}</h3>
                <p className="card-description">{feature.description}</p>
              </div>
            ))}
          </div>
          <div className="card-row">
            {features.slice(10, 14).map((feature, index) => (
              <div className="card" key={index}>
                <h3 className="card-title">{feature.title}</h3>
                <p className="card-description">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WpHostingFeatures;
