import React from 'react';
import WP24 from '../../../src/Images/WP24.svg';
import './WordPressHostingFechers.css';

const WordPressHostingFechers = () => {
    return (
        <div className="wp-container">
            {/* First Section */}
            <div className="wp-row">
                <div className="wp-card">
                    <img src={WP24} alt="WP Image 1" className="wp-card-img" />
                    <h3 className="wp-card-title">مزایای خرید هاست وردپرس</h3>
                    <p className="wp-card-text">برخی از مزایای ویژه هاست وردپرس که محبوبیت آن را در بین کاربران افزایش داده است به شرح زیر هستند :</p>
                    <div className='wp-card-ul'>
                        <ul>
                            <li>امنیت بالا</li>
                            <li>سهولت در روند مدیریت</li>
                            <li>صرفه جویی در روند هزینه ها</li>
                            <li>بهبود تجربه کاربری</li>
                        </ul>
                        <ul>
                            <li>قابلیت بکاپ گیری</li>
                            <li>افزایش سرعت</li>
                            <li>مقیاس پذیری</li>
                            <li>امنیت بالا</li>
                        </ul>
                    </div>
                </div>
                <div className="wp-card">
                    <img src={WP24} alt="WP Image 2" className="wp-card-img" />
                    <h3 className="wp-card-title">کاربرد هاست وردپرس</h3>
                    <p className="wp-card-text">اگر به محبوبت و گستردگی استفاده از هاست وردپرس دقت کنید، می‌توانید به کاربردهای آن پی ببرید . از کاربردهای هاست وردپرس می‌توان به موارد زیر اشاره کرد :</p>
                    <div className='wp-card-ull'>
                        <ul>
                            <li>بهبود سرعت و عملکرد وب سایت وردپرسی</li>
                            <li>پشتیبان گیری از اطلاعات در حجم بالا</li>
                            <li>مدیریت آسان وب سایت های مختلف</li>
                            <li>دسترسی به امکانات بیشتر</li>
                            <li>افزایش امنیت اطلاعات و داده های وب سایت</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Second Section */}
            <div className="wp-full-card">
                <img src={WP24} alt="WP Image 3" className="wp-card-img" />
                <h3 className="wp-card-title">نحوه اتصال به هاست وردپرس</h3>
                <p className="wp-full-card-text">اتصال هاست به وردپرس علاوه‌بر کنترل پنل از طریق افزونه نیز امکان پذیر است. برای اتصال از طریق کنترل پنلی مانند سی پنل ابتدا در قسمت  zone editor با توجه به عنوان ساب دامنه یک A Record بسازید. ساب دامنه مورد نظر را در بخش Name ثبت کنید. سپس عدد 14400 را در قسمت و وارد کنید. در ادامه بر روی گزینه A ‌کلیک کرده و با رفتن به بخش Address آی پی خود را به ثبت برسانید. در پایان گزینه Add Record را انتخاب کنید .</p>
            </div>

            {/* Third Section */}
            <div className="wp-row">
                <div className="wp-card">
                    <img src={WP24} alt="WP Image 4" className="wp-card-img" />
                    <h3 className="wp-card-title">کنترل پنل هاست وردپرس</h3>
                    <p className="wp-card-text">پنل هاست یا همان پنل هاستینگ به مدیران وب‌سایت کمک می‌کند تا وبسایت خود را با استفاده از رابط‌های کاربری گرافیکی به راحتی مدیریت کنند. در حال حاضر انواع مختلفی از کنترل پنل‌های وردپرس وجود دارند. ادمین دایرکت و سی پنل دو مورد از محبوب‌ترین و رایج‌ترین کنترل پنل‌های استفاده شده برای وب‌سایت‌های وردپرسی هستند که هر یک مزایا و ویژگی‌های متفاوتی دارند .</p>
                </div>
                <div className="wp-card">
                    <img src={WP24} alt="WP Image 5" className="wp-card-img" />
                    <h3 className="wp-card-title">دسترسی‌های هاست مخصوص وردپرس</h3>
                    <p className="wp-card-text">دسترسی‌های هاست مخصوص وردپرس به امنیت بیشتر اطلاعات موجود در وب‌سایت وردپرسی کمک می‌کند. از جمله این دسترسی‌ها می‌توان موارد زیر را نام برد :
                        /INDEX/DELETE/ALTER / SELECT/ CREATE /UPDATE INSERT/DROP
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WordPressHostingFechers;
