import "./BannerHostingNew.css";
import MainBtn from "../../Components/MainBtn/MainBtn.js";
import SecondBtn from "../../Components/SecondBtn/SecondBtn.js";
import mouse from "../../Images/mouse.png";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function BannerHostingNew({ title1, title2, description, imageUrl , mainbtn , secondbtn , mainbtnUrl , SecondBtnUrl }) {
  const [bhh, setBhh] = useState(0);

  useEffect(() => {
    setBhh(window.innerHeight - 131);
  }, []);

  const handleScroll = () => {
    document.getElementById("hosting").scrollIntoView();
  };

  return (
    <div
      style={{ height: bhh + "px" }}
      className="container-fluid banner-container d-flex align-items-center"
    >
      <div className="container d-flex justify-content-between align-items-center w-100 banner-container-inside">
        <div className="banner-hosting-image-container">
          {imageUrl && <img src={imageUrl} />}
        </div>
        <div className="ml-160 banner-hosting-title-container d-flex flex-column align-items-end">
          <div className="title-1">
            <h1 className="c-white bold rtl">{title1}</h1>
          </div>
          <div className="title-2">
            <p className="c-white bold rtl">
              <h3 className="c-main">{title2}</h3>
            </p>
          </div>
          <div className="title-text">
            <p className="c-white-70 rtl text-align-justify">{description}</p>
          </div>
          <div className="d-flex justify-content-end w-100 bcheap">
            <a className="link1 acheap" href={mainbtnUrl}>
              <MainBtn text={mainbtn} />
            </a>
            <div className="l-btn"></div>
            <Link className="link1 acheap" to={SecondBtnUrl}>
              <SecondBtn text={secondbtn} />
            </Link>
          </div>
        </div>
      </div>
      <div className="mouse-container d-flex flex-column align-items-center">
        <img className="mb-10 pointer" src={mouse} onClick={handleScroll} />
        <p className="c-white-20 default">اسکرول کنید</p>
      </div>
    </div>
  );
}

export default BannerHostingNew;
