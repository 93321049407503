import React from 'react';
import './EconomicHostingInfo.css';
import MainBtn from "../../Components/MainBtn/MainBtn.js";


function EconomicHostingInfo({title, description , imageUrl}) {
    return (
        <div className="economic-hosting-info-container">
            <div className="content-container">
                <div className="text-container">
                    <h1>{title}</h1>
                    <p>{description}</p>
                    <div className="consultation-button">
                        <a className="link1" href="/contact">
                            <MainBtn text="درخواست مشاوره" />
                        </a>
                    </div>
                </div>
                <div className="image-container">
                    {imageUrl && <img src={imageUrl} alt={title} />}
                </div>
            </div>
        </div>
    );
}

export default EconomicHostingInfo;
