import "./AboutUs.css";
import KCard from "../../Components/KCard/KCard";
import MainBtn from "../../Components/MainBtn/MainBtn";
import aboutUs from "../../Images/about-us.png";
import FooterMob from "../../sections/FooterMob/FooterMob";
import helpers from "../../helpers";
import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <>
      <div className="About-us-container d-flex flex-column-reverse flex-lg-row justify-content-center justify-content-lg-between align-items-center">
        <div className="d-flex flex-column justify-content-between h-100 about-content">
          <div className="xs-mb-50 d-flex flex-column align-items-end about-us-contents">
            <p className="c-white-90 rtl about-us-content-1">تا اینجا مسیر چگونه بود ؟<br></br>
              در ابتدا سال ۹۳ تیمی با نام تیم برنامه نویسی آی سی شکل گرفت . سال ۱۴۰۰ مجدد طراحی برند رو به صورت کامل انجام دادیم. حالا نام ما تیم عماد هست . در سال ۱۴۰۳ این برند رو با نام شرکت شرکت فناوری و توسعه زیر ساخت عماد در ضمینه ارائه خدمات طراحی سایت و توسعه زیر ساخت به ثبت رساندیم . حالا نیز مفتخریم که تمام خدمات و سرویس ها را با نام تجاری عماد به شما عزیزان ارائه دهیم . {" "}
            </p>
          </div>
          <div>
            {/* <div className="d-flex flex-row justify-content-between k-cards">
              <div className="kcards-top">
                <KCard title="تلگرام" number="10.1" icon="telegram" />
              </div>
              <div className="">
                <KCard title="اینستاگرام" number="12.4" icon="instagram" />
              </div>
            </div> */}
            <div>
              <div className="about-us-message-container d-flex justify-content-between">
                <div className="col-2 d-lg-none">
                  <MainBtn className="w-auto" />
                </div>
                <div className="d-none d-lg-inline">
                  <Link className='link1 pointer ' to='/contact'>
                    <MainBtn text="دریافت مشاوره" />
                  </Link>
                </div>
                <div>
                  <p className="c-white rtl about-us-message-text-1">
                    پروژه های خود را با خیال راحت به ما بسپارید!
                  </p>
                  <p className="c-white-70 rtl about-us-message-text-2">
                    حتی اگر تمایل همکاری با ما را نداشتین ، از مشاوره رایگان ما بی بهره نمانیید .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-us-image xs-mb-30 l-d-flex justify-content-center align-items-center">
          <img src={aboutUs} />
        </div>
      </div>
    </>
  );
}

export default AboutUs;
