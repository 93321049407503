import React from 'react';
import './WpHostingQM1.css'; // برای استایل‌دهی


// Img
import WPQM1 from '../../../src/Images/WPQM1.svg';
import WPQM2 from '../../../src/Images/WPQM2.svg';
import WPQM3 from '../../../src/Images/WPQM3.svg';

const WpHostingQM1 = () => {
    return (
        <div className="new-component-container">
            <div className="image-container">
                <img src={WPQM2} alt="Image 2" className='imagecontainer' />
                <img src={WPQM3} alt="Image 3" className='imagecontainer wpqm3' />
                <img src={WPQM1} alt="Image 1" className='imagecontainer' />
            </div>

            <div className="text-container ititle">
                <h2 className="title">تفاوت هاست اختصاصی وردپرس با هاست اشتراکی چیست ؟ </h2>
                <p className="description">
                    اغلب افراد هنگام خرید هاست وردپرسی در خرید نوع اشتراکی یا اختصاصی آن دچار تردید و سردرگمی می‌شوند. برای انتخاب مناسب هاست وردپرسی آشنایی با ویژگی‌های هر یک از انواع هاست ضروری است. پیش از بررسی این دو هاست بهتر است شما را به تعریف هاست اشتراکی و اختصاصی آشنا کنیم. در هاست اشتراکی منابع مربوط به سرور مختص یک سایت نیست. اما هاست وردپرس اختصاصی تنها مختص یک وب‌سایت است. مهم‌ترین تفاوت‌های هاست وردپرسی اشتراکی و اختصاصی عبارتند از :
                </p>
            </div>

            <div className="sub-container">
                <div className="sub-box">
                    <h3 className="sub-title">قیمت</h3>
                    <p className="sub-description">هاست اختصاصی به دلیل اینکه تنها مختص یک وب‌سایت است، قیمت بالاتری دارد.</p>
                </div>
                <div className="sub-box">
                    <h3 className="sub-title">امنیت</h3>
                    <p className="sub-description">اگر امنیت اطلاعات و داده‌های وب‌سایت برای شما اهمیت دارد، انتخاب هاست اختصاصی وردپرس را به شما پیشنهاد می‌کنیم. چرا که هاست وردپرس سطح امنیت بالاتری دارد.</p>
                </div>
                <div className="sub-box">
                    <h3 className="sub-title">سرعت</h3>
                    <p className="sub-description">هاست اشتراکی وردپرس در مقایسه با هاست اختصاصی ترافیک بالاتری دارد.
                        نوع مدیریت: مدیریت وب‌سایت‌ها برای افراد یکه دانش کافی ندارند با هاست اشتراکی آسان‌تر است.
                    </p>
                </div>
                <div className="sub-box">
                    <h3 className="sub-title">نوع کاربرد</h3>
                    <p className="sub-description">هاست وردپرس اختصاصی برای وب‌سایت‌هایی که کاربران بیشتری از آن بازدید می‌کنند مناسب‌تر است. اما اگر یک سایت تعداد کاربران کمتری داشته باشد، انتخاب هاست وردپرسی اشتراکی برای آن ایده‌آل‌تر به نظر می‌رسد.          </p>
                </div>
            </div>
        </div>
    );
};

export default WpHostingQM1;
