import React, { useState, useEffect } from 'react';
import './HostingPackages.css';
import spaceicon from '../../Images/Pressure.svg';

// svg imports

import accesspoint from '../../Images/Hosting/accesspoint.svg';
import circlecheck from '../../Images/Hosting/circlecheck.svg';
import cpanel from '../../Images/Hosting/cpanel.svg';
import cpu from '../../Images/Hosting/cpu.svg';
import database from '../../Images/Hosting/database.svg';
import domain from '../../Images/Hosting/domain.svg';
import email from '../../Images/Hosting/email.svg';
import firewall from '../../Images/Hosting/firewall.svg';
import ftp from '../../Images/Hosting/ftp.svg';
import hardssd from '../../Images/Hosting/hardssd.svg';
import php from '../../Images/Hosting/php.svg';
import ram from '../../Images/Hosting/ram.svg';
import ssl from '../../Images/Hosting/ssl.svg';
import stroge from '../../Images/Hosting/stroge.svg';
import website from '../../Images/Hosting/website.svg';
import wordpress from '../../Images/Hosting/wordpress.svg';
import antishaler from '../../Images/Hosting/antishaler.svg';
import io from '../../Images/Hosting/io.svg';
import softcloud from '../../Images/Hosting/softcloud.svg';


function HostingPackages({ defaultPackage, PackageName }) {
  const [selectedHosting, setSelectedHosting] = useState(defaultPackage || 'wordpress');
  const [selectedLocation, setSelectedLocation] = useState('iran');
  const [showFullDescription, setShowFullDescription] = useState({});
  const [showPopup, setShowPopup] = useState(false); // برای مدیریت باز و بسته شدن پاپ‌آپ
  const [selectedPackage, setSelectedPackage] = useState(null); // وضعیت برای پکیج انتخاب‌شده


  const hostingOptions = ['wordpress', 'cpanel', 'fileHosting', 'personal', 'highTraffic'];
  const locationOptions = ['iran', 'germany'];



  // تعریف پکیج‌ها
  const packages = {
    wordpress: {
      iran: [
        {
          name: 'نقره ای - WP',
          space: '۵۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۸۰۰,۰۰۰ تومان ',
          monthlyPrice: '۸۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/ir-wp/wp-nqrh-y',
          ram: 'رم : 2048 مگابایت',
          cpu: 'CPU : دو هسته',
          cxs: 'آنتی شلر : CXS',
          io: 'تعداد پروسس هم زمان : ۴۰ پروسس',
          firewall: 'فایروال : CSF',
          Softaclouds: 'نصب کننده اتوماتیک  Softaclouds',
        },
        {
          name: 'طلایی - WP',
          space: '۱۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۱,۰۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۰۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/ir-wp/wp-tlyy',
        },
        {
          name: 'الماس - WP',
          space: '۲۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۱,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/ir-wp/wp-lms',
        },
        {
          name: 'یاقوت - WP',
          space: '۵۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۲,۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۲۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/ir-wp/wp-yqwt',
        },
        {
          name: 'یاقوت کبود - WP',
          space: '۱۰۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۴,۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۴۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/ir-wp/wp-yqwt-khbwd',
        },
      ],
      germany: [
        {
          name: 'نقره ای - WP',
          space: '۵۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۸۰۰,۰۰۰ تومان ',
          monthlyPrice: '۸۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/de-wp/hst-wrdrps-nqrh-y',
          ram: 'رم : 2048 مگابایت',
          cpu: 'CPU : دو هسته',
          cxs: 'آنتی شلر : CXS',
          io: 'تعداد پروسس هم زمان : ۴۰ پروسس',
          firewall: 'فایروال : CSF',
          Softaclouds: 'نصب کننده اتوماتیک  Softaclouds',
        },
        {
          name: 'طلایی - WP',
          space: '۱۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۱,۰۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۰۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/de-wp/hst-tlyy',
        },
        {
          name: 'الماس - WP',
          space: '۲۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۱,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/de-wp/hst-wrdprs-lms',
        },
        {
          name: 'یاقوت - WP',
          space: '۵۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۲,۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۲۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/de-wp/hst-wrdprs-yqwt',
        },
        {
          name: 'یاقوت کبود - WP',
          space: '۱۰۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۴,۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۴۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/de-wp/hst-wrdprs-yqwt-khbwd',
        },
      ],
    },
    cpanel: {
      iran: [
        {
          name: 'سرویس برنزی',
          space: '۵۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت معمولی',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۵۰۰,۰۰۰ تومان',
          monthlyPrice: '۵۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-sy-pnl-yrn/s1',
          ram: 'رم : ۱۰۲۴ مگابایت',
          cpu: 'CPU : یک هسته',
          cxs: 'آنتی شلر : CXS',
          io: 'تعداد پروسس هم زمان : ۲۰ پروسس',
          firewall: 'فایروال : CSF',
          Softaclouds: 'نصب کننده اتوماتیک  Softaclouds',
        },
        {
          name: 'سرویس نقره ای',
          space: '۱۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت معمولی',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-sy-pnl-yrn/s2-srwys-nqrh-y',
        },
        {
          name: 'سرویس طلایی',
          space: '۲۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت معمولی',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۸۰۰,۰۰۰ تومان',
          monthlyPrice: '۸۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-sy-pnl-yrn/s3-srwys-tlyy',
        },
        {
          name: 'سرویس الماس',
          space: '۵۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۱,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-sy-pnl-yrn/s4-srwys-lms',
        },
        {
          name: 'سرویس ویژه',
          space: '۱۰۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۲,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۲۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-sy-pnl-yrn/s5-srwys-wyjh',
        }
      ],
      germany: [
        {
          name: 'سرویس طلایی',
          space: '۱۰۲۴ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت معمولی',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/linux-host/golden-package/',
          ram: 'رم : ۱۰۲۴ مگابایت',
          cpu: 'CPU : یک هسته',
          cxs: 'آنتی شلر : CXS',
          io: 'تعداد پروسس هم زمان : ۲۰ پروسس',
          firewall: 'فایروال : CSF',
          Softaclouds: 'نصب کننده اتوماتیک  Softaclouds',
        },
        {
          name: 'سرویس الماس',
          space: '۲۰۴۸ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۸۰۰,۰۰۰ تومان',
          monthlyPrice: '۸۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/linux-host/diamond-package/',
        },
        {
          name: 'سرویس یاقوت',
          space: '۵۲۱۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۱,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/linux-host/srwys-yqwt/',
        },
        {
          name: 'یاقوت کبود',
          space: '۱۰۲۴۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۲,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۲۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/linux-host/hst-lynwkhs-qtsdy-srwys-yqwt-khbwd/',
        },
        {
          name: 'سرویس ویژه',
          space: 'فضا تمام نامحدود',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۴,۵۰۰,۰۰۰ تومان',
          monthlyPrice: '۴۵۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/linux-host/specialpackage/',
        }
      ]
    },
    personal: {
      iran: [
        {
          name: 'سرویس برنزی',
          space: '۵۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های کوچک',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۵۰۰,۰۰۰ تومان',
          monthlyPrice: '۵۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-shkhsy-yrn/s1-shkhsy-brnzy/',
          ram: 'رم : ۱۰۲۴ مگابایت',
          cpu: 'CPU : یک هسته',
          cxs: 'آنتی شلر : CXS',
          io: 'تعداد پروسس هم زمان : ۲۰ پروسس',
          firewall: 'فایروال : CSF',
          Softaclouds: 'نصب کننده اتوماتیک  Softaclouds',
        },
        {
          name: 'سرویس نقره ای',
          space: '۱۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های کوچک و متوسط',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-shkhsy-yrn/s2-shkhsy-nqrh-y/',
        },
        {
          name: 'سرویس طلایی',
          space: '۲۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های متوسط و پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۸۰۰,۰۰۰ تومان',
          monthlyPrice: '۸۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-shkhsy-yrn/s3-shkhsy-tlyy/',
        },
        {
          name: 'سرویس الماس',
          space: '۵۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۱,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-shkhsy-yrn/s4-shkhsy-lms/',
        },
        {
          name: 'سرویس ویژه',
          space: '۱۰,۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های بسیار پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۲,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۲۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-shkhsy-yrn/s5-shkhsy-wyjh/',
        }
      ],
      germany: [
        {
          name: 'سرویس برنزی',
          space: '۵۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های کوچک',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۵۰۰,۰۰۰ تومان',
          monthlyPrice: '۵۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/personal/bronze-personal-service/',
          ram: 'رم : 1024 مگابایت',
          cpu: 'CPU : یک هسته',
          cxs: 'آنتی شلر : CXS',
          io: 'تعداد پروسس هم زمان : ۲۰ پروسس',
          firewall: 'فایروال : CSF',
          Softaclouds: 'نصب کننده اتوماتیک  Softaclouds',
        },
        {
          name: 'سرویس نقره ای',
          space: '۱۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های کوچک و متوسط',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/personal/silver-personal-service/',
        },
        {
          name: 'سرویس طلایی',
          space: '۲۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های متوسط و پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۸۰۰,۰۰۰ تومان',
          monthlyPrice: '۸۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/personal/golden-personal-service/',
        },
        {
          name: 'سرویس الماس',
          space: '۵۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۱,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/personal/diamond-personal-service/',
        },
        {
          name: 'سرویس ویژه',
          space: '۱۰,۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های بسیار پربازدید',
          storage: 'هارد پرسرعت SSD-NvMe',
          yearlyPrice: '۲,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۲۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/personal/special-personal-service/',
        }
      ]
    },
    highTraffic: {
      iran: [
        {
          name: 'سرویس برنزی',
          space: '۵۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۸۰۰,۰۰۰ تومان',
          monthlyPrice: '۸۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/iran-pro-cpanel-hosting/s1/',
          ram: 'رم : 2048 مگابایت',
          cpu: 'CPU : دو هسته',
          cxs: 'آنتی شلر : CXS',
          io: 'تعداد پروسس هم زمان : ۴۰ پروسس',
          firewall: 'فایروال : CSF',
          Softaclouds: 'نصب کننده اتوماتیک  Softaclouds',
        },
        {
          name: 'سرویس نقره ای',
          space: '۱۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۱,۰۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۰۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/iran-pro-cpanel-hosting/s2-prbzdyd-nqrh-y-1/',
        },
        {
          name: 'سرویس طلایی',
          space: '۲۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۱,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/iran-pro-cpanel-hosting/s3-prbzdyd-tlyy-1/',
        },
        {
          name: 'سرویس الماس',
          space: '۵۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۲,۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۲۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/iran-pro-cpanel-hosting/s4-prbzdyd-lms-1/',
        },
        {
          name: 'سرویس ویژه',
          space: '۱۰,۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۴,۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۴۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/iran-pro-cpanel-hosting/s5-prbzdyd-wyjh-1',
        }
      ],
      germany: [
        {
          name: 'سرویس برنزی',
          space: '۵۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۸۰۰,۰۰۰ تومان',
          monthlyPrice: '۸۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/pro/s1/',
          ram: 'رم : 2048 مگابایت',
          cpu: 'CPU : دو هسته',
          cxs: 'آنتی شلر : CXS',
          io: 'تعداد پروسس هم زمان : ۴۰ پروسس',
          firewall: 'فایروال : CSF',
          Softaclouds: 'نصب کننده اتوماتیک  Softaclouds',
        },
        {
          name: 'سرویس نقره ای',
          space: '۱۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۱,۰۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۰۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/pro/s2-prbzdyd-nqrh-y/',
        },
        {
          name: 'سرویس طلایی',
          space: '۲۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۱,۴۰۰,۰۰۰ تومان',
          monthlyPrice: '۱۴۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/pro/s3-prbzdyd-tlyy/',
        },
        {
          name: 'سرویس الماس',
          space: '۵۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۲,۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۲۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/pro/s4-prbzdyd-lms/',
        },
        {
          name: 'سرویس ویژه',
          space: '۱۰,۰۰۰ مگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'اکانت ایمیل نامحدود',
          domains: 'تعداد دامنه نامحدود',
          databases: 'تعداد دیتابیس نامحدود',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل CPanel',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'نصب کننده اتوماتیک وردپرس',
          phpVersion: 'امکان تغییر نسخه php',
          suitableFor: 'مناسب برای سایت‌های پربازدید',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۴,۶۰۰,۰۰۰ تومان',
          monthlyPrice: '۴۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/pro/s5-prbzdyd-wyjh/',
        }
      ],
    },
    fileHosting: {
      iran: [
        {
          name: 'DL1 - هاست فایل',
          space: '۴ گیگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'ایمیل نامحدود',
          domains: 'امکان اجرا در ساب دامین',
          databases: 'هاست فایل دیتابیس ندارد',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل Directadmin',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'امکان اتصال مستقیم به سایت وردپرس',
          phpVersion: 'فعال بودن php بر روی سرور',
          suitableFor: 'مناسب برای نگهداری فایل های شما',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۱۳۲,۰۰۰ تومان',
          monthlyPrice: '۱۲,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-dnlwd-yrn/dl1-hst-fyl/',
          ram: 'رم : - مگابایت',
          cpu: 'CPU : - هسته',
          cxs: 'آنتی شلر : -',
          io: 'تعداد پروسس هم زمان : - پروسس',
          firewall: 'فایروال : -',
          Softaclouds: 'انتقال رایگان',
        },
        {
          name: 'DL2 - هاست فایل',
          space: '۱۰ گیگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'ایمیل نامحدود',
          domains: 'امکان اجرا در ساب دامین',
          databases: 'هاست فایل دیتابیس ندارد',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل Directadmin',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'امکان اتصال مستقیم به سایت وردپرس',
          phpVersion: 'فعال بودن php بر روی سرور',
          suitableFor: 'مناسب برای نگهداری فایل های شما',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۳۳۰,۰۰۰ تومان',
          monthlyPrice: '۳۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-dnlwd-yrn/hst-fyl-dl2/',
        },
        {
          name: 'DL3 - هاست فایل',
          space: '۳۰ گیگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'ایمیل نامحدود',
          domains: 'امکان اجرا در ساب دامین',
          databases: 'هاست فایل دیتابیس ندارد',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل Directadmin',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'امکان اتصال مستقیم به سایت وردپرس',
          phpVersion: 'فعال بودن php بر روی سرور',
          suitableFor: 'مناسب برای نگهداری فایل های شما',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۹۹۰,۰۰۰ تومان',
          monthlyPrice: '۹۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-dnlwd-yrn/dl3-hst-fyl-1/',
        },
        {
          name: 'DL4 - هاست فایل',
          space: '۶۰ گیگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'ایمیل نامحدود',
          domains: 'امکان اجرا در ساب دامین',
          databases: 'هاست فایل دیتابیس ندارد',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل Directadmin',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'امکان اتصال مستقیم به سایت وردپرس',
          phpVersion: 'فعال بودن php بر روی سرور',
          suitableFor: 'مناسب برای نگهداری فایل های شما',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۱,۹۸۰,۰۰۰ تومان',
          monthlyPrice: '۱۸۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-dnlwd-yrn/dl4-hst-fyl-1/',
        },
        {
          name: 'DL5 - هاست فایل',
          space: '۱۲۰ گیگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'ایمیل نامحدود',
          domains: 'امکان اجرا در ساب دامین',
          databases: 'هاست فایل دیتابیس ندارد',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل Directadmin',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'امکان اتصال مستقیم به سایت وردپرس',
          phpVersion: 'فعال بودن php بر روی سرور',
          suitableFor: 'مناسب برای نگهداری فایل های شما',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۳,۹۶۰,۰۰۰ تومان',
          monthlyPrice: '۳۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-dnlwd-yrn/dl5-hst-fyl-1/',
        }
      ],
      germany: [
        {
          name: 'DL1 - هاست فایل',
          space: '۴ گیگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'ایمیل نامحدود',
          domains: 'امکان اجرا در ساب دامین',
          databases: 'هاست فایل دیتابیس ندارد',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل Directadmin',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'امکان اتصال مستقیم به سایت وردپرس',
          phpVersion: 'فعال بودن php بر روی سرور',
          suitableFor: 'مناسب برای نگهداری فایل های شما',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۱۳۲,۰۰۰ تومان',
          monthlyPrice: '۱۲,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-dnlwd/dl1/',
          ram: 'رم : - مگابایت',
          cpu: 'CPU : - هسته',
          cxs: 'آنتی شلر : -',
          io: 'تعداد پروسس هم زمان : - پروسس',
          firewall: 'فایروال : -',
          Softaclouds: 'انتقال رایگان',
        },
        {
          name: 'DL2 - هاست فایل',
          space: '۱۰ گیگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'ایمیل نامحدود',
          domains: 'امکان اجرا در ساب دامین',
          databases: 'هاست فایل دیتابیس ندارد',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل Directadmin',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'امکان اتصال مستقیم به سایت وردپرس',
          phpVersion: 'فعال بودن php بر روی سرور',
          suitableFor: 'مناسب برای نگهداری فایل های شما',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۳۳۰,۰۰۰ تومان',
          monthlyPrice: '۳۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-dnlwd/dl2-hst-fyl/',
        },
        {
          name: 'DL3 - هاست فایل',
          space: '۳۰ گیگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'ایمیل نامحدود',
          domains: 'امکان اجرا در ساب دامین',
          databases: 'هاست فایل دیتابیس ندارد',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل Directadmin',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'امکان اتصال مستقیم به سایت وردپرس',
          phpVersion: 'فعال بودن php بر روی سرور',
          suitableFor: 'مناسب برای نگهداری فایل های شما',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۹۹۰,۰۰۰ تومان',
          monthlyPrice: '۹۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-dnlwd/dl3-hst-fyl/',
        },
        {
          name: 'DL4 - هاست فایل',
          space: '۶۰ گیگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'ایمیل نامحدود',
          domains: 'امکان اجرا در ساب دامین',
          databases: 'هاست فایل دیتابیس ندارد',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل Directadmin',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'امکان اتصال مستقیم به سایت وردپرس',
          phpVersion: 'فعال بودن php بر روی سرور',
          suitableFor: 'مناسب برای نگهداری فایل های شما',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۱,۹۸۰,۰۰۰ تومان',
          monthlyPrice: '۱۸۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-dnlwd/dl4-hst-fyl/',
        },
        {
          name: 'DL5 - هاست فایل',
          space: '۱۲۰ گیگابایت فضا',
          bandwidth: 'پهنای باند نامحدود',
          email: 'ایمیل نامحدود',
          domains: 'امکان اجرا در ساب دامین',
          databases: 'هاست فایل دیتابیس ندارد',
          ftp: 'تعداد FTP نامحدود',
          controlPanel: 'کنترل پنل Directadmin',
          ssl: 'SSL رایگان',
          wordpressInstaller: 'امکان اتصال مستقیم به سایت وردپرس',
          phpVersion: 'فعال بودن php بر روی سرور',
          suitableFor: 'مناسب برای نگهداری فایل های شما',
          storage: 'هارد پرسرعت SSD',
          yearlyPrice: '۳,۹۶۰,۰۰۰ تومان',
          monthlyPrice: '۳۶۰,۰۰۰/ ماهانه',
          orderButton: 'سفارش دهید',
          link: 'https://emad.team/hosting/index.php/store/hst-dnlwd/dl5-hst-fyl/',
        }
      ],
    },
  };



  useEffect(() => {
    if (defaultPackage) {
      setSelectedHosting(defaultPackage);
    }
  }, [defaultPackage]);

  const handleHostingChange = (hostingType) => {
    setSelectedHosting(hostingType);
  };

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  const handleShowMoreClick = (index) => {
    setShowFullDescription(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handlePopupOpen = (packageInfo) => {
    setShowPopup(true);
    setSelectedPackage(packageInfo); // ذخیره اطلاعات پکیج انتخاب‌شده
  };


  const handlePopupClose = () => {
    setShowPopup(false);  // مخفی کردن پاپ‌آپ
  };

  return (
    <div className="hosting-packages-container">
      <h2>{PackageName || 'پلن ها'}</h2>
      <div className="hosting-options">
        {hostingOptions.map((hosting) => (
          <button
            key={hosting}
            className={`hosting-option ${selectedHosting === hosting ? 'active' : ''}`}
            onClick={() => handleHostingChange(hosting)}
          >
            {hosting === 'wordpress' ? 'هاست وردپرس' :
              hosting === 'cpanel' ? 'هاست سی پنل' :
                hosting === 'personal' ? 'هاست شخصی' :
                  hosting === 'highTraffic' ? 'هاست پربازدید' :
                    hosting === 'fileHosting' ? 'هاست دانلود' : ''}
          </button>
        ))}
      </div>

      <div className="location-options">
        {locationOptions.map((location) => (
          <button
            key={location}
            className={`location-option ${selectedLocation === location ? 'active' : ''}`}
            onClick={() => handleLocationChange(location)}
          >
            {location === 'iran' ? 'ایران' : 'آلمان'}
          </button>
        ))}
      </div>

      <div className="package-list">
        {packages[selectedHosting][selectedLocation].map((packageInfo, index) => (
          <div key={index} className="package-card">
            <h2>{packageInfo.name}</h2>
            <p className="monthly-price">
              <span className="price">{packageInfo.monthlyPrice.split('/')[0]} تومان</span>
              <span className="duration">/{packageInfo.monthlyPrice.split('/')[1]}</span>
            </p>
            <p className="space-info">
              <img src={spaceicon} alt="space icon" className="space-icon" />
              {packageInfo.space}
            </p>
            <p className="space-info">
              <img src={spaceicon} alt="space icon" className="space-icon" />
              {packageInfo.bandwidth}
            </p>
            <p className="space-info">
              <img src={spaceicon} alt="space icon" className="space-icon" />
              {packageInfo.email}
            </p>
            <p className="space-info">
              <img src={spaceicon} alt="space icon" className="space-icon" />
              {packageInfo.domains}
            </p>

            {/* نمایش پاپ‌آپ هنگام کلیک روی "جزئیات بیشتر" */}
            <span
              className="show-more"
              onClick={() => handlePopupOpen(packageInfo)} // ارسال اطلاعات پکیج
            >
              جزئیات بیشتر
            </span>


            <button className="select-package">
              <a href={packageInfo.link} target="_blank" rel="noopener noreferrer">
                {packageInfo.orderButton}
              </a>
            </button>
          </div>
        ))}
      </div>

      {/* پاپ‌آپ */}
      {showPopup && (
        <div className={`popup-overlay ${showPopup ? 'show' : 'hide'}`}>
          <div className="popup-content">
            <button className="close-popup" onClick={() => {
              setShowPopup(false);  // به محض کلیک روی بستن، کلاس hide اضافه می‌شود
            }}>
              &#10006;
            </button>
            <h3>{selectedPackage.name}</h3>
            <p className="monthly-price">
              <span className="duration"> / {selectedPackage.monthlyPrice.split('/')[1]}</span>
              <span className="price">
                {selectedPackage.monthlyPrice.split('/')[0]}&nbsp;
                تومان
              </span>
            </p>

            {/* توضیحات ردیفی */}
            <div className="description-row">
              <p><img src={stroge} alt="icon" /> {selectedPackage.space}</p>
              <p><img src={accesspoint} alt="icon" /> {selectedPackage.bandwidth}</p>
              <p><img src={spaceicon} alt="icon" /> {selectedPackage.storage}</p>
              <p><img src={php} alt="icon" /> {selectedPackage.phpVersion}</p>
              <p><img src={database} alt="icon" /> {selectedPackage.databases}</p>
              <p><img src={domain} alt="icon" /> {selectedPackage.domains}</p>
              <p><img src={ftp} alt="icon" /> {selectedPackage.ftp}</p>
              <p><img src={email} alt="icon" /> {selectedPackage.email}</p>
              <p><img src={cpanel} alt="icon" /> {selectedPackage.controlPanel}</p>
              <p><img src={wordpress} alt="icon" /> {selectedPackage.wordpressInstaller}</p>
            </div>


            {/* ردیف دوم توضیحات */}
            <div className="description-row description-rowr ">
              <p><img src={ram} alt="icon" /> {packages[selectedHosting][selectedLocation][0].ram}</p>
              <p><img src={cpu} alt="icon" /> {packages[selectedHosting][selectedLocation][0].cpu}</p>
              <p><img src={firewall} alt="icon" /> {packages[selectedHosting][selectedLocation][0].firewall}</p>
              <p><img src={ssl} alt="icon" /> {packages[selectedHosting][selectedLocation][0].ssl}</p>
              <p><img src={website} alt="icon" /> {packages[selectedHosting][selectedLocation][0].suitableFor}</p>
              <p><img src={antishaler} alt="icon" /> {packages[selectedHosting][selectedLocation][0].cxs}</p>
              <p><img src={io} alt="icon" /> {packages[selectedHosting][selectedLocation][0].io}</p>
              <p><img src={softcloud} alt="icon" /> {packages[selectedHosting][selectedLocation][0].Softaclouds}</p>

              <button className="select-package-popup">
                <a href={selectedPackage.link} target="_blank" rel="noopener noreferrer">
                  {selectedPackage.orderButton}
                </a>
              </button>

            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HostingPackages;