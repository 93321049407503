// src/Components/SplitContent/SplitContent.js

import React from 'react';
import './SplitContentHosting.css';

const SplitContentHosting = ({ title, description, imageUrl, reverse, titleTag = 'h2' }) => {
    const TitleTag = titleTag;

    return (
        <div className="split-content-container">
            <div className={`main-boxx ${reverse ? 'reverse' : ''}`}>
                <div className="image-box">
                    {imageUrl && <img src={imageUrl} alt={title} />}
                </div>
                <div className="text-box">
                    <TitleTag>{title}</TitleTag>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};

export default SplitContentHosting;
