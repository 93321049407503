// src/Components/FAQComponent/FAQComponent.js

import React, { useState } from 'react';
import './HostingFAQ.css';

const FAQComponent = ({ faqs, imageUrl }) => {
  return (
    <div className="faq-container">
      <div className="faq-content">
        {/* دایو سمت چپ برای سوالات متداول */}
        <div className="faq-left">
          <h3 className="h3Faqs">سؤالات متداول</h3>
          <span className="faq-tag">FAQs</span>
          
          {/* سوالات و جواب‌ها */}
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>

        {/* دایو سمت راست برای تصویر */}
        <div className="faq-right">
          {imageUrl && <img src={imageUrl} alt="FAQ" />}
        </div>
      </div>
    </div>
  );
};

// کامپوننت برای نمایش هر سوال و جواب
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);  // تغییر وضعیت باز و بسته شدن
  };

  return (
    <div className="faq-item">
      <div className="faq-question" onClick={toggleAnswer}>
        <h4 className="qstyle">{question}</h4>
        {/* فقط سوال قابل کلیک است, بدون نمایش دکمه "نمایش بده" و "پنهان کن" */}
      </div>
      
      {/* اگر isOpen برابر true بود، جواب را نمایش بده */}
      {isOpen && <p className="faq-answer">{answer}</p>}
    </div>
  );
};

export default FAQComponent;
