import React from 'react';
import './DynamicContentHostingOne.css';

const DynamicContent = ({ title, description, imageUrl, reverseOrder }) => {
  return (
    <div className="dynamic-content-container">
      <div className="content-box">
        {reverseOrder ? (
          // اگر reverseOrder true باشد، ابتدا تصویر نمایش داده می‌شود
          <>
            {imageUrl && <img src={imageUrl} alt={title} />}
            <h2>{title}</h2>
            <p>{description}</p>
          </>
        ) : (
          // اگر reverseOrder false باشد، به صورت پیش‌فرض تصویر در انتها نمایش داده می‌شود
          <>
            <h2>{title}</h2>
            <p>{description}</p>
            {imageUrl && <img src={imageUrl} alt={title} />}
          </>
        )}
      </div>
    </div>
  );
};

export default DynamicContent;
