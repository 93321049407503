// react
import { Link } from "react-router-dom";

// images
import arrowdown from "../../Images/arrowdown.png";

// css
import "./Navbar.css";

function Navbar() {
  return (
    <div className="navbar-container">
      <div className="navbar-item c-white-70 pointer">
        <Link className="link1" to="/">
          <p>صفحه اصلی</p>
        </Link>
      </div>
      <div className="navbar-item c-white-70 pointer">
        <Link className="link1" to="/web-design/">
          <p>طراحی سایت</p>
        </Link>
      </div>
      <div className="navbar-item c-white-70 pointer dropd-hover">
        <Link className="link1" to="/hosting/">
          <p>
            <img className="navbar-item-icon" src={arrowdown} alt="arrowdown" />{" "}
            &nbsp; هاست و دامنه
          </p>
          <ul className="dropd-item dd-items rtl">
            <Link className="link1" to="/CheapHosting/">
              <li className="dd-item dd-border">هاست ارزان</li>
            </Link>
            <Link className="link1" to="/SharedHost/">
              <li className="dd-item dd-border">هاست اشتراکی</li>
            </Link>
            <Link className="link1" to="/WordPressHosting/">
              <li className="dd-item dd-border">هاست وردپرس</li>
            </Link>
            {/* سایر آیتم‌ها */}
          </ul>
        </Link>
      </div>
      <div className="navbar-item c-white-70 pointer">
        <Link className="link1" to="/design/">
          <p>طراحی گرافیک</p>
        </Link>
      </div>
      <div className="navbar-item c-white-70 pointer">
        <Link className="link1" to="/application/">
          <p>طراحی اپلیکیشن</p>
        </Link>
      </div>
      <div className="navbar-item c-white-70 pointer">
        <Link className="link1" to="/contact/">
          <p>تماس با ما</p>
        </Link>
      </div>

      <div className="navbar-item dropd-hover c-white-70 pointer">
        <p>
          <span>
            <img className="navbar-item-icon" src={arrowdown} alt="arrowdown" />
          </span>
          <span>سایر</span>
        </p>
        <ul className="dropd-item dd-items rtl">
          <Link className="link1" to="/FAQs/">
            <li className="dd-item dd-border">سوالات متداول</li>
          </Link>
          <Link className="link1" to="/about/">
            <li className="dd-item dd-border">درباره ما</li>
          </Link>
          <Link className="link1" to="/blog/">
            <li className="dd-item dd-border">بلاگ</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
